import { TrackJS } from 'trackjs';

import { notification } from '../components/Notification';
import type { Operation } from '../stores/StoreBase';

import { parseAxiosError, parseAxiosStatus } from './axios';
import { hashCode } from './elements/hashCode';
import { isProd } from './isProd';
import { sentenceCaseMulti } from './strings';

export function getUnknownErrorText(e: unknown): string {
  if (typeof e === 'string') {
    return e;
  }

  if (e instanceof Error && !!e.message) {
    return e.message;
  }

  return JSON.stringify(e);
}

export function getNetworkErrorInfo(error: any): { disconnected: boolean; message: string } {
  let disconnected = false;
  let message = error?.message || 'Unknown error';

  const parsedMessage = parseAxiosError(error, true);
  if (parsedMessage) {
    // Use a better message for "Network Error".
    if (parsedMessage === 'Network Error') {
      disconnected = true;
      message = 'You are disconnected.';
    } else if (error.code === 'ECONNABORTED' && !isProd) {
      message = `The request to ${error.config.url} timed out.`;
    } else {
      message = parsedMessage;
    }
  }

  return { disconnected: disconnected, message: message };
}

interface ErrorHandlerOptions {
  operation?: Operation;
  showNotification?: boolean;
  toastId?: string;
  onForbidden?: (error: any) => void;
}

export function handleNetworkError(error: any, options: ErrorHandlerOptions = {}) {
  const { operation, showNotification = false, toastId, onForbidden } = options;

  console.trace('Error during operation', error);

  if (!error?.isAxiosError) {
    TrackJS.track(error);
  }

  const errorData = error?.response?.data;
  let conflict = false;

  const { message, disconnected } = getNetworkErrorInfo(error);

  const errorStatus = parseAxiosStatus(error);
  if (errorStatus === 409) {
    conflict = true;
  }
  if (errorStatus === 403 && onForbidden) {
    onForbidden(error);
  }

  // Update operation if provided
  if (operation) {
    operation.clearErrors();
    operation.status = 'finished';
    operation.error = message;
    operation.errorData = errorData;
    operation.errorStatus = errorStatus;
    operation.conflict = conflict;
    operation.disconnected = disconnected;
  }

  if (showNotification) {
    const notificationKey = toastId || `${hashCode(String(message))}-error`;
    notification.error(
      {
        message: 'Error',
        description: message ? sentenceCaseMulti(String(message)) : undefined,
      },
      {
        toastId: notificationKey,
        autoClose: false,
      }
    );
  }

  return message;
}
