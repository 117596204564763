import React, { useState } from 'react';

import { getUnknownErrorText } from '../util/error';

import { Button } from './Button';
import { ContactSupportForm } from './ContactSupportForm';

type FriendlyErrorProps = {
  error: Error | string | undefined;
  resetBoundary: () => void;
};

export const FriendlyError = ({ error, resetBoundary }: FriendlyErrorProps) => {
  const errorMessage = getUnknownErrorText(error).replace('TrackJS Caught: ', '');

  const [showCustomerSupportForm, setShowCustomerSupportForm] = useState(false);

  return (
    <div className="relative flex flex-col gap-2 p-2">
      <div className="text-xl">Something went wrong</div>
      <div className="text-red">Error: {errorMessage}</div>

      <div className="flex items-center gap-2">
        <Button type="axi-secondary" onClick={() => setShowCustomerSupportForm(true)}>
          Contact support
        </Button>
        <Button type="axi-secondary" onClick={() => void resetBoundary()}>
          Refresh page
        </Button>
      </div>
      <ContactSupportForm
        type="modal"
        initialSupportType="Problem"
        setShowModal={setShowCustomerSupportForm}
        showModal={showCustomerSupportForm}
        additionalDetails={errorMessage}
      />
    </div>
  );
};
