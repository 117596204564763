// cSpell:ignore COMMITHASH prealpha

import { reaction } from 'mobx';
import { TrackJS } from 'trackjs';

import { axiom } from '../axiom';
import { notification } from '../components/Notification';
import { initAxios } from '../shared/init/initAxios';
import { viewStore } from '../stores/ViewStore';

import './font-awesome/index';
import { initMobx } from './mobx';

// eslint-disable-next-line import/order
import '../styles/vendor/index.scss';
// @ts-expect-error ts doesn't know that this file exists...
// eslint-disable-next-line import/order
import '../styles/main.css';

// @ts-expect-error @todo: fix this
await import('../styles/base/fonts.scss');

initAxios();
initMobx();

if (process.env.NODE_ENV === 'production' && axiom.enableJSMonitoring) {
  TrackJS.install({
    token: '4a7758982e8e441a9d937ba30092201e',
    version: axiom.version !== 'v0.0.0-prealpha-dev' ? axiom.version : process.env.COMMITHASH || 'test',
    console: {
      // Current 'warn' usages seem to indicate problems we want to investigate
      warn: true,
    },
  });

  // Add info about where the application is running to help with error filtering
  TrackJS.addMetadata('environment', window.location.host.replace(/\./g, '-'));
}

if (process.env.DEBUG_MODE_ON) {
  /* eslint-disable no-console */
  console.debug = console.log;
}

// React to "page" changes / navigation events.
let lastUrl = viewStore.activeUrl;
reaction(
  () => [viewStore.activeUrl],
  () => {
    if (lastUrl !== viewStore.activeUrl) {
      // Ignore if it's a redirect.
      if (viewStore.activeQuery.redirect !== '1') {
        // Clear notifications on page navigation.
        notification.dismiss();
      }

      lastUrl = viewStore.activeUrl;
    }
  }
);

// Update meta viewport if device's width is less than our `minWidth`
// No need to do this on a `PublicViewport` page since they're fully responsive.
// https://www.brendanlong.com/setting-a-minimum-viewport-width-for-responsive-pages.html
const pathname = window.location.pathname.toLowerCase();
if (
  !pathname.startsWith('/oauth') &&
  !pathname.startsWith('/connect') &&
  !pathname.startsWith('/init') &&
  !pathname.startsWith('/invited') &&
  !pathname.startsWith('/login') &&
  !pathname.startsWith('/recover') &&
  !pathname.startsWith('/register')
) {
  // Add min-width to body for non `PublicViewport` pages.
  const minWidth = 1024;
  document.body.style.minWidth = `${minWidth}px`;

  const viewport = document.querySelector('meta[name=viewport]');
  if (viewport) {
    if (screen.width < minWidth) {
      document.head.removeChild(viewport);

      const newViewport = document.createElement('meta');
      newViewport.setAttribute('name', 'viewport');
      newViewport.setAttribute('content', `width=${minWidth}`);
      document.head.appendChild(newViewport);
    }
  }
}
