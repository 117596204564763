import { type AxiosResponse } from 'axios';

import { axiom } from '../axiom';

export function getBaseUrl(basePath = '/internal') {
  return `${axiom.apiUrl}${basePath}`;
}

export function parseAxiosError(error: any, skipFallbackToStringError = false): any {
  let message;

  if (error) {
    if (error.response?.data) {
      if (error.response.data.error) {
        message = error.response.data.error;
      } else if (error.response.data.message) {
        message = error.response.data.message;
      }
    }
    if (!message && error.message) {
      message = error.message;
    }
    if (!message && !skipFallbackToStringError) {
      message = String(error);
    }
  }

  return message;
}

export function parseAxiosStatus(error: any): number {
  if (error) {
    if (error.response?.status) {
      return error.response.status;
    } else if (error.request?.status) {
      return error.request.status;
    }
  }

  // Default "unknown error" status
  return 520;
}

interface AxiomError extends Error {
  traceId?: string;
}

function NewAxiomError(msg: string, traceId?: string): AxiomError {
  const error = new Error(msg) as AxiomError;
  error.traceId = traceId;

  return error;
}

export async function tryAxiosRequest<T>(request: Promise<T>): Promise<T> {
  return request.catch((err) => {
    throw NewAxiomError(parseAxiosError(err), err?.response?.headers['x-axiom-trace-id']);
  });
}

export function useAxiosRequest<T = any>(
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setError: React.Dispatch<React.SetStateAction<string | undefined>>
) {
  const executeRequest = (request: Promise<AxiosResponse<T>>, next?: (response: AxiosResponse<T>) => void) => {
    setLoading(true);
    setError(undefined);

    request
      .then((response: AxiosResponse<T>) => {
        setError(undefined);

        if (next) {
          next(response);
        }
      })
      .catch((err) => {
        // Use String(err) to prevent React from blowing up if we return an object by mistake.
        const message = err.message || 'Network request failed due to an unknown error';
        setError(message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return { executeRequest: executeRequest };
}
