import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useMemo, useState, type PropsWithChildren } from 'react';
import { type SubmitHandler, useWatch } from 'react-hook-form';
import { z } from 'zod';

import { FormRadioGroup, FormRadioGroupItem } from 'dash/components/form/FormRadioGroup';
import { FormTextArea } from 'dash/components/form/FormTextArea';
import { userStatusStore } from 'dash/stores/UserStatusStore';
import { useAttachmentUpload } from 'dash/util/hooks/util/useAttachmentUpload';
import { cn } from 'dash/util/styles';

import { AxiIcons } from '../assets/Icons-axe_ui_lib';
import { useZodForm } from '../util/hooks/hookform/useZodForm';

import { Button } from './Button';
import { BasicForm } from './form/BasicForm';
import { Icon } from './Icon';
import { Link } from './Link';
import { Modal_DEPRECATED } from './Modal';
import { notification } from './Notification';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from './primitives/Accordion';
import { Popover, PopoverTrigger, PopoverContent } from './primitives/Popover';

const SUPPORT_PROBLEM_URL =
  'mailto:support@axiom.co?subject=I%20want%20to%20report%20a%20problem&body=Something%20seems%20wrong...';
const SUPPORT_FEATURE_URL =
  'mailto:support@axiom.co?subject=I%20want%20to%20request%20a%20feature&body=I%27d%20like%20to%20request%20a%20feature%20that...';
const SUPPORT_QUESTION_URL = 'mailto:support@axiom.co?subject=I%20want%20to%20ask%20a%20question&body=How%20do%20I...';

type CustomerSupportRequest = {
  supportType: string;
  details: string;
  userEmail: string;
  userName: string;
  usersRoute: string;
  attachments?: {
    file: File;
    preview?: string;
  }[];
};

const contactSupportFormSchema = z.object({
  supportType: z.enum(['Problem', 'Feature', 'Question']),
  details: z.string().min(30).nonempty('Details are required.'),
});

type ContactSupportFormSchema = z.infer<typeof contactSupportFormSchema>;

interface ContactSupportFormProps {
  placeHolderText?: string;
  initialDetails?: ContactSupportFormSchema['details'];
  initialSupportType?: ContactSupportFormSchema['supportType'];
  setShowModal?: (show: boolean) => void;
  type: 'modal' | 'popover';
  showModal?: boolean;
  additionalDetails?: string;
}

export const ContactSupportForm = observer(function ContactSupportForm(props: ContactSupportFormProps) {
  const { setShowModal, showModal, type, additionalDetails } = props;
  const [isAccordionOpen, setIsAccordionOpen] = useState('');
  const {
    attachments,
    setAttachments,
    formattedTotalFileSize,
    filesExceedMaximumBytes,
    isDraggingOver,
    fileInputRef,
    handleFileChange,
    handleDragEnter,
    handleDragLeave,
    handleDragOver,
    handleDrop,
    handlePaste,
    removeAttachment,
    isInvalidFiles,
    clearInvalidFilesFlag,
  } = useAttachmentUpload({ setIsAccordionOpen: setIsAccordionOpen });

  const containerRef = useRef<HTMLDivElement>(null);

  const form = useZodForm({
    schema: contactSupportFormSchema,
    defaultValues: {
      supportType: props.initialSupportType ?? 'Problem',
      details: props.initialDetails ?? '',
    },
  });

  // `form` is not stable, but these are
  const getValues = form.getValues;
  const { isDirty } = form.formState;

  const selectedSupportType = useWatch({ control: form.control, name: 'supportType' });
  const details = useWatch({ control: form.control, name: 'details' });

  const hasDetails = useMemo(() => details.length > 0, [details]);

  const contactSupportMutation = useMutation({
    networkMode: 'always', //Detects no internet connection
    mutationFn: async (newContactSupportData: CustomerSupportRequest) => {
      const _details = additionalDetails
        ? `${newContactSupportData.details}\n\nAdditional details (Automatically added by frontend):\n${additionalDetails}`
        : newContactSupportData.details;

      // Need to use form data here because we need to encode the binary images for upload to plain.
      const formData = new FormData();
      formData.append('supportType', newContactSupportData.supportType);
      formData.append('details', _details);
      formData.append('userEmail', newContactSupportData.userEmail);
      formData.append('userName', newContactSupportData.userName);
      formData.append('usersRoute', newContactSupportData.usersRoute);

      if (newContactSupportData.attachments) {
        newContactSupportData.attachments.forEach((attachment, index) => {
          formData.append('files', attachment.file);
        });
      }

      // Endpoint in frontend-server is setup specifically to handle multi-part form-data with multer middleware.
      return axios.post('/frapi/plain', formData, {
        baseURL: '',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    onSuccess: () => {
      form.reset();
      if (setShowModal) {
        setShowModal(false);
      }
      setAttachments([]);
      notification.success({
        message: 'Customer support message successfully sent.',
        description: "We'll get back to you as soon as possible.",
      });
    },
    onError: (err) => {
      let errorMessage = 'An unknown error occurred.';
      if (axios.isAxiosError(err)) {
        if (err.response) {
          if (err.response.status === 429) {
            errorMessage = 'Too many requests, please try again in 15 minutes.';
          } else {
            errorMessage = err.response.data || err.message;
          }
        } else if (err.request) {
          // No inernet for example
          errorMessage = 'No response received from server.';
        } else {
          // If nothing above was caught its something else
          errorMessage = err.message;
        }
      }
      notification.error({
        message: 'Whoops! Something unexpected happened.',
        description: errorMessage,
      });
    },
  });

  const onSubmit: SubmitHandler<ContactSupportFormSchema> = (data, event) => {
    event?.preventDefault();
    event?.stopPropagation();

    if (isDirty && userStatusStore.user) {
      const values = data;
      contactSupportMutation.mutate({
        userEmail: userStatusStore.user.email,
        userName: userStatusStore.user?.name ?? 'None',
        usersRoute: window.location.href,
        attachments: attachments,
        ...values,
      });
    }
  };

  const handleOpenAccordion = (itemValue: string) => {
    setIsAccordionOpen(itemValue);
  };

  useEffect(() => {
    if (isDraggingOver === true) {
      setIsAccordionOpen('attachment-accordion');
    }
  }, [isDraggingOver]);

  useEffect(() => {
    if (isInvalidFiles) {
      setTimeout(() => {
        clearInvalidFilesFlag();
      }, 2500);
    }
  }, [clearInvalidFilesFlag, isInvalidFiles]);

  return (
    <ContactSupportModalOrPopover type={type} showModal={showModal} setShowModal={setShowModal}>
      <BasicForm form={form} onSubmit={onSubmit}>
        <div className="flex w-[400px] flex-col">
          <div>
            <h4 className="text-lg font-semibold leading-loose">Customer support</h4>
            <h5 className="text-sm text-muted">
              Whether it’s a feature request, bug report, or a general question, <br /> feel free to provide all the
              relevant information below.
            </h5>
          </div>
          <FormRadioGroup
            //@ts-expect-error react-hook-form doesn't like union types
            control={form.control}
            rootClassName="!mb-0 text-sm"
            className="flex space-x-4 pt-4"
            name="supportType"
            variant="radio"
          >
            <FormRadioGroupItem
              value="Problem"
              className={selectedSupportType === 'Problem' ? 'text-gray-12' : 'text-gray-11'}
            >
              Get help
            </FormRadioGroupItem>
            <FormRadioGroupItem
              value="Feature"
              className={selectedSupportType === 'Feature' ? 'text-gray-12' : 'text-gray-11'}
            >
              Feature request
            </FormRadioGroupItem>
            <FormRadioGroupItem
              value="Question"
              className={selectedSupportType === 'Question' ? 'text-gray-12' : 'text-gray-11'}
            >
              General questions
            </FormRadioGroupItem>
          </FormRadioGroup>
          <div
            role="presentation"
            aria-label="Drag and drop area"
            ref={containerRef}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={(e) => {
              handleDragOver(e);
            }}
            onDrop={handleDrop}
            className="group w-full"
          >
            <div className="rounded-t border hover:border-gray-10">
              <FormTextArea
                className="h-24 !border-0 bg-transparent p-0"
                rootClassName="!mb-0 p-1 bg-base rounded-t"
                placeholder={
                  selectedSupportType === 'Feature'
                    ? props.placeHolderText ?? "I'd like to request a feature that..."
                    : selectedSupportType === 'Problem'
                    ? 'Let us know how we can help...'
                    : 'How do I...'
                }
                control={form.control}
                name="details"
                expand
                autoComplete="off"
              />
            </div>
            <Accordion type="single" collapsible value={isAccordionOpen} onValueChange={handleOpenAccordion}>
              <AccordionItem value="attachment-accordion">
                <AccordionTrigger
                  className={`group/add-screenshot flex w-full items-center justify-between space-x-2 rounded-b border border-t-transparent bg-[--gray-03] px-2 py-1 text-left hover:border-t hover:border-[--gray-10] hover:bg-[--gray-03] data-[state=open]:rounded-none`}
                >
                  <div className="flex items-center space-x-1">
                    <Icon name={AxiIcons.Image} className="h-5 w-5 text-gray-11" />
                    <div className="space-x-2">
                      <span>Screenshots</span>
                      {attachments.length > 0 ? (
                        <span className={`font-mono text-sm tabular-nums text-gray-11`}>
                          <span className={`${attachments.length > 25 ? 'text-red' : ''}`}>
                            [{attachments.length}/25]
                          </span>{' '}
                          <span className={`${filesExceedMaximumBytes ? 'text-red' : ''}`}>
                            [{formattedTotalFileSize}/6MB]
                          </span>
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="grid h-4 w-4 place-items-center">
                      <Icon
                        name="chevron-right"
                        className="text-gray-11 transition-transform duration-200 ease-out group-data-[state=open]/add-screenshot:rotate-90"
                      />
                    </div>
                  </div>
                </AccordionTrigger>
                <AccordionContent
                  className={`group/accordion-content h-24 w-full overflow-x-auto overflow-y-hidden rounded-b border border-t-0 bg-base p-3 ${
                    isInvalidFiles ? 'pointer-events-none' : ''
                  }`}
                  onClick={() => {
                    if (!isInvalidFiles && fileInputRef.current) {
                      fileInputRef.current?.click();
                    }
                  }}
                >
                  <div
                    className={cn(
                      `relative flex cursor-pointer items-center justify-center rounded border border-gray-07`,
                      attachments.length > 0 && !isInvalidFiles
                        ? 'justify-start border-none'
                        : `h-full justify-center border-dashed hover:border-gray-10 hover:bg-[--gray-03] ${
                            isDraggingOver ? 'border-[--gray-10] bg-[--gray-03]' : ''
                          }`,
                      isInvalidFiles === true ? 'pointer-events-none border-red-06' : ''
                    )}
                  >
                    {isInvalidFiles ? (
                      <h4 className="text-center text-sm leading-relaxed">
                        <span>
                          Unsupported file type <br />{' '}
                          <span className="text-gray-10">only (.png* .jpeg* .webp*) are allowed.</span>
                        </span>
                      </h4>
                    ) : (
                      <React.Fragment>
                        {attachments.length > 0 ? (
                          <div className="flex items-center gap-2 pr-2">
                            {attachments.map((attachment, index) => (
                              <div
                                key={index}
                                className="group/thumbnail relative z-0 h-[72px] w-[72px] flex-shrink-0 select-none rounded bg-[--gray-06] p-0.5"
                              >
                                <img
                                  src={attachment.preview}
                                  alt={`Attachment ${index + 1}`}
                                  className="h-full w-full rounded object-cover"
                                />
                                <div
                                  role="button"
                                  aria-label="close button"
                                  className="absolute -right-1.5 -top-1.5 z-10 flex h-4 w-4 cursor-pointer items-center justify-center rounded-full bg-[--gray-11] text-[--gray-01] opacity-0 hover:bg-[--gray-12] group-hover/thumbnail:opacity-100"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    removeAttachment(index);
                                  }}
                                >
                                  <Icon name={AxiIcons.Cross} width={8} height={8} />
                                </div>
                              </div>
                            ))}
                            <Button
                              type="axi-secondary"
                              icon="plus"
                              className={`h-[72px] w-[72px] hover:!border-[--gray-10] ${
                                isDraggingOver ? '!bg-[--gray-03]' : ''
                              }`}
                            />
                          </div>
                        ) : (
                          <h4 className="text-center text-sm leading-relaxed">
                            <span>
                              Drag image(s), paste from clipboard or <span className="underline">upload a file</span>
                            </span>
                            <br />
                            <span className="text-gray-10">(.png* .jpeg* .webp*)</span>
                          </h4>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
            <FilePaster handlePaste={handlePaste} />
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              accept=".png,.jpeg,.jpg,.webp"
              multiple
              className="hidden"
            />
          </div>
          <div className="flex w-full items-center justify-between pt-4">
            <h5 className="text-sm text-muted">
              You can also email us at{' '}
              <Link
                className={'text-gray-11 hover:text-gray-12'}
                href={
                  selectedSupportType === 'Feature'
                    ? SUPPORT_FEATURE_URL
                    : selectedSupportType === 'Problem'
                    ? SUPPORT_PROBLEM_URL
                    : SUPPORT_QUESTION_URL
                }
              >
                support@axiom.co{' '}
              </Link>
              <br /> or join the{' '}
              <Link href="https://axiom.co/discord" target="_blank" className={'text-gray-11 hover:text-gray-12'}>
                Discord!
              </Link>
            </h5>
            <Button
              type="axi-primary"
              loading={contactSupportMutation.isPending}
              disabled={
                !hasDetails || contactSupportMutation.isPending || attachments.length > 25 || filesExceedMaximumBytes
              }
              onClick={(e: any) => {
                onSubmit(getValues(), e);
              }}
            >
              Send message
            </Button>
          </div>
        </div>
      </BasicForm>
    </ContactSupportModalOrPopover>
  );
});

//Need to break this out of the useAttachment hook because it needs to unmount when the popover is closed.
//As to make sure you cant past with the popover closed.
const FilePaster = ({ handlePaste }: { handlePaste: (event: ClipboardEvent) => void }) => {
  useEffect(() => {
    document.addEventListener('paste', handlePaste);

    return () => {
      document.removeEventListener('paste', handlePaste);
    };
  }, [handlePaste]);

  return null;
};

const ContactSupportModalOrPopover = ({
  type,
  showModal,
  setShowModal,
  children,
}: PropsWithChildren & {
  setShowModal?: (show: boolean) => void;
  type: 'modal' | 'popover';
  showModal?: boolean;
}) => {
  return (
    <React.Fragment>
      {type === 'modal' && (
        <Modal_DEPRECATED
          onCancel={() => {
            if (setShowModal) {
              setShowModal(false);
            }
          }}
          closable
          visible={showModal}
          width={450}
          footer={null}
          destroyOnClose
          noBodyPadding
        >
          {children}
        </Modal_DEPRECATED>
      )}
      {type === 'popover' && <ContactSupportPopover>{children}</ContactSupportPopover>}
    </React.Fragment>
  );
};

const ContactSupportPopover = ({ trigger, children }: { trigger?: React.ReactElement; children: React.ReactNode }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        {trigger ? (
          trigger
        ) : (
          <button className="border-b border-transparent px-4 font-medium hover:border-b-hover hover:bg-element-hover">
            Support
          </button>
        )}
      </PopoverTrigger>
      <PopoverContent id="customer-support-content" className="mr-1 max-w-[450px]">
        {children}
      </PopoverContent>
    </Popover>
  );
};
