// cSpell:ignore toastify
// eslint-disable-next-line import/order
import 'react-toastify/dist/ReactToastify.css';
import isString from 'lodash/isString';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Portal } from 'react-portal';
import { toast, ToastContainer, type ToastOptions } from 'react-toastify';

import { viewStore } from '../stores/ViewStore';
import { parseBooleanString } from '../util/strings';
import { cn } from '../util/styles';

import { Icon } from './Icon';
import { Link } from './Link';
import { NewLinesToBreaks } from './NewLinesToBreaks';

import styles from './Notification.module.scss';

const CloseButton = ({ closeToast }: { closeToast: (e: React.MouseEvent<HTMLElement>) => void }) => (
  <button data-dont-close-event-panel-on-click className={styles.close} onClick={closeToast}>
    <Icon name="times" /> <span className="sr-only">Close</span>
  </button>
);

// CDEUTSCH: in `react-toastify@9` the duration has been removed and seems
// to be part of the CSS now.
// Not sure how to adjust the duration so going to leave it and see if it's still an issue.
//
// Shorten the animation time of the toastify transition.
// Without this if you retry a failed network request, which first
// dismisses the old toast, and then errors again; the 2nd toast
// may be dismissed because the close animation finishes after the
// 2nd toast has been displayed.
// const Slide = cssTransition({
//   enter: `Toastify__slide-enter`,
//   exit: `Toastify__slide-exit`,
//   duration: [250, 0],
//   appendPosition: true,
// });

// Configure toast defaults

export const AxiomToastContainer = observer(function AxiomToastContainer() {
  const isTesting = parseBooleanString(process.env.PLAYWRIGHT_TESTING);

  return (
    <Portal>
      <ToastContainer
        className={cn(isTesting && '!hidden')}
        hideProgressBar
        closeOnClick={false}
        closeButton={CloseButton}
        icon={false}
        draggable={false}
        theme={viewStore.themeMode}
      />
    </Portal>
  );
});

export interface NotifyProps {
  message: React.ReactNode;
  description?: React.ReactNode;
}

interface NotificationProps extends NotifyProps {
  className?: string;
  icon?: React.ReactElement; // Using ReactElement because we don't want to all ReactNode types
}

const Notification = ({ className, message, description, icon }: NotificationProps) => {
  let iconNode;

  if (icon) {
    iconNode = React.Children.only(icon);
    iconNode = React.cloneElement(iconNode, {
      ...iconNode.props,
      className: cn(styles.icon, iconNode.props.className),
    });
  }

  return (
    <div data-dont-close-event-panel-on-click className={cn(styles.notification, className)}>
      <div className={styles.title}>
        {iconNode ? iconNode : null}
        <div className={styles.message}>{message}</div>
      </div>
      {description ? (
        <div className={styles.description}>
          {isString(description) ? <NewLinesToBreaks text={description} /> : description}
        </div>
      ) : null}
    </div>
  );
};

function saved(options?: ToastOptions) {
  // Delay toasting the notification, in case the logic also involves a redirect.
  // This will prevent navigation from immediately dismissing the notification.
  window.setTimeout(() => {
    toast.success(<Notification message="Saved" icon={<Icon name="check-circle" color="green" />} />, {
      autoClose: 3000,
      ...options,
    });
  }, 50);
}

function success(argsProps: NotifyProps, options?: ToastOptions) {
  // Delay toasting the notification, in case the logic also involves a redirect.
  // This will prevent navigation from immediately dismissing the notification.
  window.setTimeout(() => {
    toast.success(<Notification {...argsProps} icon={<Icon name="check-circle" color="green" />} />, options);
  }, 50);
}

function progressSuccess(argsProps: NotifyProps, options?: ToastOptions) {
  const defaultOptions: ToastOptions = {
    autoClose: false,
    className: 'getting-started-success',
  };
  const defaultArgs: Partial<NotifyProps> = {
    description: <Link to="/getting-started">Next step {'->'}</Link>,
  };
  // Delay toasting the notification, in case the logic also involves a redirect.
  // This will prevent navigation from immediately dismissing the notification.
  window.setTimeout(() => {
    toast.success(
      <Notification
        {...{ ...defaultArgs, ...argsProps }}
        className={styles.progressSuccess}
        icon={<Icon name="check-circle" color="green" />}
      />,
      { ...defaultOptions, ...options }
    );
  }, 50);
}

function info(argsProps: NotifyProps, options?: ToastOptions) {
  // Delay toasting the notification, in case the logic also involves a redirect.
  // This will prevent navigation from immediately dismissing the notification.
  window.setTimeout(() => {
    toast.info(<Notification {...argsProps} icon={<Icon name="exclamation-circle" color="blue" />} />, options);
  }, 50);
}

function error(argsProps: NotifyProps, options?: ToastOptions) {
  // Delay toasting the notification, in case the logic also involves a redirect.
  // This will prevent navigation from immediately dismissing the notification.
  window.setTimeout(() => {
    toast.error(<Notification {...argsProps} icon={<Icon name="times-circle" color="red" />} />, options);
  }, 50);
}

// FUTURE: separate notify function?

export function allowOutsideClick(event: MouseEvent | TouchEvent) {
  // Allow FocusTrap to click outside itself when it comes to Toasts and our Overlay component.
  if (
    event.target &&
    ((event.target as HTMLElement).closest('.Toastify') ||
      (event.target as HTMLElement).classList.contains('axiom-overlay'))
  ) {
    return true;
  } else {
    return false;
  }
}

export const notification = {
  saved: saved,
  success: success,
  progressSuccess: progressSuccess,
  info: info,
  error: error,
  dismiss: toast.dismiss,
};
