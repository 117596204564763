import React, { useEffect } from 'react';

import { cn } from '../util/styles';

import { AxiomToastContainer } from './Notification';

type MainViewportProps = {
  children?: React.ReactNode;
};

export const MainViewport = ({ children }: MainViewportProps) => {
  useEffect(() => {
    window.document.addEventListener('click', onDocumentClick);
    window.document.addEventListener('keydown', onDocumentKeyDown);

    return () => {
      window.document.removeEventListener('click', onDocumentClick);
      window.document.removeEventListener('keydown', onDocumentKeyDown);
      window.document.body.classList.remove('keyboard-in-use');
    };
  });

  const onDocumentClick = (event: MouseEvent) => {
    // `Clickable` will trigger `onClick`, so we need to see if the mouse is actually in use.
    if ((event.screenX === 0 && event.screenY === 0) || event.detail === 0) {
      return;
    }
    window.document.body.classList.remove('keyboard-in-use', 'keyboard');
  };

  const onDocumentKeyDown = (event: KeyboardEvent) => {
    // Add a class to `<body>` to indicate the customer is navigating using the keyboard.
    if (event.key === 'Tab' || ((event.metaKey || event.ctrlKey) && event.key === 'F6')) {
      window.document.body.classList.add('keyboard-in-use', 'keyboard');
    }
  };

  return (
    <div className={cn('isolate h-full w-full')}>
      {children}
      <AxiomToastContainer />
    </div>
  );
};
